<template>
  <HomeNav />
  <div class="flex justify-center items-center flex-col">
    <h2 class="text-center text-2xl m-4 font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-cyan-300 to-indigo-400 w-fit text-glow">Create a private ChatGPT with your own data.</h2>
    <h2 class="text-center text-2xl m-4 font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-indigo-400 to-cyan-300 w-fit text-glow">Build as many different datasets as you'd like - and control who has access to them.</h2>
  </div>
</template>

<script>
// @ is an alias to /src
import HomeNav from '@/components/HomeNav.vue'
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { ref, onMounted } from 'vue'
import { db, auth } from '@/firebase'

export default {
  name: 'HomeView',
  components: {
    HomeNav
  },
  methods: {
  },
  mounted: function() {
    
  }
}
</script>

<style scoped>
/* .custom-shadow {
  -webkit-box-shadow:0px 0px 29px 0px rgba(45,255,196,0.67);
  -moz-box-shadow: 0px 0px 29px 0px rgba(45,255,196,0.67);
  box-shadow: 0px 0px 29px 0px rgba(45,255,196,0.67);
} */
.text-glow {
  text-shadow: rgba(0, 145, 255, 0.67) 0px 0px 21px;
}
</style>