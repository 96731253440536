<template>
  <div class="h-screen w-screen">
    <router-view />
  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}

:root {
  /* background-image: linear-gradient(to bottom, red, blue); */
  background: -webkit-gradient(linear, left top, left bottom, from(#0f172a), to(#27272a)) fixed;
  background-color: #27272a;
}
</style>