import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import { getAuth } from "firebase/auth"

const firebaseConfig = {
    apiKey: "AIzaSyCXcRcOLAsv5zCHUGj34GZfWzkzkMpVpOI",
    authDomain: "scribeai-development.firebaseapp.com",
    projectId: "scribeai-development",
    storageBucket: "scribeai-development.appspot.com",
    messagingSenderId: "140728575086",
    appId: "1:140728575086:web:1c3e46ecb7b0b21881727f"
};

const app = initializeApp(firebaseConfig)

export const auth = getAuth(app)
export const db = getFirestore(app)