<template>
    <MainNav/>
    <h1 class="ml-2">Welcome, {{ fullName }}</h1>
</template>

<script>
import MainNav from '@/components/MainNav.vue';

import { useStore } from 'vuex'
import { ref } from 'vue'

export default {
    name: 'Main',
    components: {
        MainNav
    },
    setup() {
        const store = useStore()
        
        const fullName = ref(`${store.state.user.displayName}`)
        return { fullName }
    }
}
</script>