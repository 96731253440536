<template>
    <div>
        <div class="grid grid-cols-6">
            <BackButton @back-clicked="$emit('back-clicked')" class="flex flex-center items-center ml-2 col-start-1" />
            <h1 class="text-center text-2xl font-bold mt-2  col-start-2 col-end-6">Editing Model: {{ modelData.name }}</h1>
        </div>
        <table class="min-w-full">
            <thead>
                <tr>
                    <th class="py-2 px-4 text-start border-b">File Name</th>
                    <th class="py-2 px-4 text-center border-b">Size (MB)</th>
                    <th class="py-2 px-4 text-center border-b">Type</th>
                    <th class="py-2 px-4 border-b"></th>
                    <th class="py-2 px-4 border-b"></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(file, index) in fileList">
                    <td class="py-2 px-4 border-b border-slate-500">{{ file.fileName }}</td>
                    <td class="py-2 px-4 border-b text-center border-slate-500">{{ parseFloat((file.fileSize/1000000).toFixed(2)) }}</td>
                    <td class="py-2 px-4 border-b text-center border-slate-500">{{ file.fileType }}</td>
                    <td class="py-2 px-4 border-b text-center border-slate-500">
                        <button @click="handleDelete(index)" class="px-4 py-2 bg-red-500 text-white hover:bg-red-600 transition-all rounded-md font-bold" v-if="hasFileDeletePerms">Delete</button>
                    </td>
                    <td class="py-2 px-4 border-b text-center border-slate-500">
                        <button @click="handleView(index)" class="px-4 py-2 bg-blue-500 text-white hover:bg-blue-600 transition-all rounded-md font-bold">View</button>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="flex flex-col items-center">
            <div :class="divClass()" class="w-full flex flex-col items-center transition-all duration-500">
                <label for="upload-pdf"
                        class="px-4 py-2 bg-green-500 text-white hover:bg-green-600 transition-all rounded-md font-bold mt-2">Add Document(s)</label>
                <input @change="documentsSelected" type="file" id="upload-pdf" accept=".pdf" multiple="multiple" class="hidden">
                <ul v-if="showSubmit" class="mt-2">
                    <h4 class="text-center font-bold">Selected Files:</h4>
                    <li v-for="file in fileArray" class="font-sm">
                        <p>{{ file.name }}</p>
                    </li>
                </ul>
                <button class="font-bold bg-green-500 rounded p-1 pr-3 pl-3 hover:bg-green-600 mt-10 transition-all" @click="addDocuments"
                        v-if="showSubmit">Submit</button>
            </div>
            <span class="loader mt-12" v-show="showAddFileLoader"></span>
        </div>
        <ConfirmDelete :showModal="showModal" :showDeleteLoader="showDeleteLoader" @confirmed="deleteFile" @cancelled="showModal = false" modalMessage="Delete file?" />
    </div>
</template>

<script>
import BackButton from '@/components/BackButton.vue'
import ConfirmDelete from './ConfirmDelete.vue'

import { ref } from 'vue'
import { useStore } from 'vuex'

import { getDoc, doc, updateDoc } from '@firebase/firestore'
import { db } from '../firebase/index';
import { getStorage, ref as storageRef, deleteObject, uploadBytes } from "firebase/storage";

export default {
    name: 'showEditView',
    components: {
        BackButton,
        ConfirmDelete
    },
    emits: ["back-clicked"],
    props: {
        userData: {
            type: Object,
        },
        modelData: {
            type: Object
        }
    },
    setup(props, context) {
        const store = useStore()
        const storage = getStorage();
        // console.log(props.userData)
        // console.log(props.modelData)
        const fileList = ref([])

        const dataSetID = props.modelData.id
        const orgID = props.userData.orgID
        const modelName = props.modelData.name

        let showModal = ref(false)
        let showDeleteLoader = ref(false)
        let showAddFileLoader = ref(false)
        let hasFileDeletePerms = ref(props.userData.modelAdmin[3])

        let showSubmit = ref(false)
        let fileArray = ref([])
        let filePaths

        let idOfInterest

        let shrinkInputs = ref(false)

        const divClass = () => (shrinkInputs.value ? 'h-0 opacity-0' : 'h-40 opacity-100');

        const getModel = async () => {
            // Get dataset document
            const dataSetQuery = await getDoc(doc(db, `organizations/${orgID}/datasets/${dataSetID}`))
            const dataSetData = dataSetQuery.data()

            // Get fileList
            fileList.value = dataSetData.fileList
        }

        const handleDelete = async(id) => {
            idOfInterest = id
            showModal.value = true
        }

        const deleteFile = async () => {
            showDeleteLoader.value = true
            // 0. Get endpoint
            const querySnapshot = await getDoc(doc(db, `organizations/${orgID}`))
            if (querySnapshot.empty) {
                console.error(`Organization doesn't exist in database`)
                return
            }
            const orgData = querySnapshot.data()
            const endpoint = orgData.endpoint


            // 1. Get file path and id
            const fileToDelete = fileList.value[idOfInterest]
            const filePath = fileToDelete.storagePath
            const fileID = idOfInterest

            // 2. Remove file from Cloud Storage
            try {
                await deleteObject(storageRef(storage, filePath))
                console.log('deleted from cloud storage')
            } catch (error) {
                console.error(error)
            }

            // 3. Update DOM
            try {
                const index = fileList.value.findIndex(file => file.id === fileID);
                fileList.value.splice(index, 1);
            } catch (error) {
                console.error(error)
            }

            // 4. Remove file from fileList in Firestore
            try {
                const datasetRef = doc(db, `organizations/${orgID}/datasets/${dataSetID}`)
                await updateDoc(datasetRef, {
                    fileList: fileList.value
                })
                console.log('deleted from firestore')
            } catch (error) {
                console.error(error)
            }

            // 5. Remove all associated vectors from Weaviate schema
            try {
                const fileName = fileToDelete.fileName
                const url = `${process.env.VUE_APP_FUNCTIONS_URL}/api/deleteObjects`
                const options = {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ endpoint: endpoint, className: modelName, fileName: fileName })
                };
                const deletionResponse = await fetch(url, options)
                const a = await deletionResponse.json()
            } catch (error) {
                console.error(error)
            }

            // setTimeout(() => {showDeleteLoader.value = false; showModal.value = false}, 1000)
            showDeleteLoader.value = false
            showModal.value = false
        }

        const handleView = async (id) => {
            console.log(id)
        }

        const viewFile = async () => {
            return
        }

        const documentsSelected = async (e) => {
            showSubmit.value = true
            filePaths = e.target.files
            fileArray.value = e.target.files
            return
        }

        const addDocuments = async () => {
            shrinkInputs.value = true
            showAddFileLoader.value = true

            // 1. Add files to Cloud Storage
            try {
                const fileKeys = Object.keys(fileArray.value)
                const uploadPromises = []
                fileKeys.forEach(key => {
                    uploadPromises.push(uploadToFirebaseStorage(fileArray.value[key], dataSetID))
                })

                const uploadResults = await Promise.all(uploadPromises)
            } catch (error) {
                console.error(error)
            }

            // 2. Read text from each PDF, and add to Weaviate
            try {
                // Get endpoint
                let endpoint
                try {
                    const orgQuerySnapshot = await getDoc(doc(db, `organizations/${orgID}`))
                    if (orgQuerySnapshot.empty) {
                        console.error(`Organization doesn't exist in database`)
                        return
                    }
                    const orgData = orgQuerySnapshot.data()
                    endpoint = orgData.endpoint
                } catch (error) {
                    console.error(error)
                }
                if (filePaths) {
                    const vectorUploadPromises = []
                    Array.prototype.forEach.call(fileArray.value, function (filePath) {
                        vectorUploadPromises.push(processFile(filePath, endpoint));
                    })
                    const vectorUploadResults = await Promise.all(vectorUploadPromises);
                }
            } catch (error) {
                console.error(error)
            }

            // 3. Add file info to fileList
            const filesToAdd = []
            Array.prototype.forEach.call(filePaths, function (file) {
                filesToAdd.push({
                    fileName: file.name,
                    fileType: file.type,
                    fileSize: file.size,
                    storagePath: `organizations/${orgID}/datasets/${dataSetID}/${file.name}`
                })
            });

            const newFileList = fileList.value.concat(filesToAdd)

            // 4. Write new fileList to dataset in Firestore
            try {
                const datasetRef = doc(db, `organizations/${orgID}/datasets/${dataSetID}`)
                await updateDoc(datasetRef, {
                    fileList: newFileList
                })
            } catch (error) {
                console.error(error)
            } finally {
                filePaths = []
                // Selected file DOM stuff
                fileArray.value = filePaths
                // Loader stuff
                showSubmit.value = false
                shrinkInputs.value = false
                showAddFileLoader.value = false
                // Full file list DOM
                fileList.value = newFileList
            }
        }

        const uploadToFirebaseStorage = async (file, dataSetID) => {
            const storage = getStorage()
            const fileName = file.name
            const cloudStorageRef = storageRef(storage, `organizations/${orgID}/datasets/${dataSetID}/${fileName}`)

            return new Promise(function (resolve, reject) {
                uploadBytes(cloudStorageRef, file).then(() => {
                    resolve('Upload successful')
                }).catch(error => {
                    reject(`Upload failed: ${error}`)
                })
            })
        }

        const processFile = (filePath, endpoint) => {
            return new Promise(async (resolve, reject) => {
                const fr = new FileReader();

                fr.onload = async function () {
                    const pdf = await pdfjsLib.getDocument({ data: fr.result }).promise;
                    let text = "";

                    for (let i = 1; i <= pdf.numPages; i++) {
                        const page = await pdf.getPage(i);
                        const content = await page.getTextContent();
                        text += content.items.map(item => item.str).join(' ') + "\n\n";
                    }

                    try {
                        const result = await sendTextToCloudFunction(text, modelName, filePath.name, endpoint);
                        resolve(result);
                    } catch (error) {
                        reject(error);
                    }
                };

                fr.readAsArrayBuffer(filePath);
            });
        };

        const sendTextToCloudFunction = async (text, className, fileName, endpoint) => {
            const url = `${process.env.VUE_APP_FUNCTIONS_URL}/api/addObjects`
            const options = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ text: text, className: className, documentTitle: fileName, endpoint: endpoint })
            };

            return new Promise(function (resolve, reject) {
                fetch(url, options).then((response) => {
                    resolve(response)
                }).catch(error => {
                    reject(`Cloud function failed: ${error})`)
                })
            })
        }

        getModel()

        return { fileList, handleDelete, deleteFile, handleView, viewFile, showModal, showDeleteLoader, showAddFileLoader, hasFileDeletePerms, addDocuments, documentsSelected, showSubmit, fileArray, divClass }
    }
}
</script>


<style scoped>
.loader {
    color: #27ed5f;
    position: relative;
    font-size: 11px;
    background: #27ed5f;
    animation: escaleY 1s infinite ease-in-out;
    width: 1em;
    height: 4em;
    animation-delay: -0.16s;
}

.loader:before,
.loader:after {
    content: '';
    position: absolute;
    top: 0;
    left: 2em;
    background: #27ed5f;
    width: 1em;
    height: 4em;
    animation: escaleY 1s infinite ease-in-out;
}

.loader:before {
    left: -2em;
    animation-delay: -0.32s;
}

@keyframes escaleY {

    0%,
    80%,
    100% {
        box-shadow: 0 0;
        height: 4em;
    }

    40% {
        box-shadow: 0 -2em;
        height: 5em;
    }
}
</style>