<template>
    <div>
        <!-- Main modal -->
        <div v-if="showModal" tabindex="-1" aria-hidden="true"
            class="flex flex-col items-center justify-center overflow-y-auto overflow-x-hidden fixed inset-0 z-50:h-full">
            <div class="relative"> <!-- Container with fixed height -->
                <div :class="showDeleteLoader ? '-translate-y-8' : 'translate-y-0'" class="relative p-4 w-full max-w-md h-full md:h-auto transition-transform duration-500 ease-in-out">
                    <!-- Modal content -->
                    <div class="relative p-4 text-center bg-slate-700 rounded-lg shadow dark:bg-gray-800 sm:p-5">
                        <button @click="$emit('cancelled')" type="button"
                            class="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white">
                            <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clip-rule="evenodd"></path>
                            </svg>
                            <span class="sr-only">Close modal</span>
                        </button>
                        <svg class="text-gray-400 dark:text-gray-500 w-11 h-11 mb-3.5 mx-auto" aria-hidden="true"
                            fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd"
                                d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                                clip-rule="evenodd"></path>
                        </svg>
                        <p class="mb-4 text-gray-200 dark:text-gray-100">{{ modalMessage }}</p>
                        <div class="flex justify-center items-center space-x-4">
                            <button @click="$emit('cancelled')" type="button"
                                class="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600">
                                No, cancel
                            </button>
                            <button type="submit"
                                class="py-2 px-3 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-900"
                                @click="$emit('confirmed')">
                                Yes, I'm sure
                            </button>
                        </div>
                    </div>
                </div>
                <div class="flex justify-center h-16 -mt-8">
                    <span class="distinguished-loader mt-12" v-if="showDeleteLoader"></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ConfirmDelete',
        props: {
            showModal: {
                type: Boolean,
                default: false
            },
            showDeleteLoader: {
                type: Boolean,
                default: false
            },
            modalMessage: {
                type: String,
                default: 'Confirm deletion?'
            }
        },
        emits: [
            'cancelled',
            'confirmed'
        ]
    }
</script>

<style scoped>
span.content {
    all: revert;
}

.distinguished-loader {
    position: relative;
    font-size: 11px;
    color: #f51d1d;
    background: #f51d1d;
    animation: escaleY 1s infinite ease-in-out;
    width: 1em;
    height: 4em;
    animation-delay: -0.16s;
}

.distinguished-loader:before,
.distinguished-loader:after {
    content: '';
    position: absolute;
    top: 0;
    left: 2em;
    background: #f51d1d;
    width: 1em;
    height: 4em;
    animation: escaleY 1s infinite ease-in-out;
}

.distinguished-loader:before {
    left: -2em;
    animation-delay: -0.32s;
}

@keyframes escaleY {

    0%,
    80%,
    100% {
        box-shadow: 0 0;
        height: 4em;
    }

    40% {
        box-shadow: 0 -2em;
        height: 5em;
    }
}
</style>