<template>
    <section id="chat" class="flex flex-col h-max">
        <div class="flex w-full flex-1">
            <div class="w-1/5 bg-slate-800 h-full">
                <BackButton @back-clicked="$emit('back-clicked')" class="flex justify-center mb-2" />
                <div class="flex justify-center">
                    <button id="newChatButton"
                        class="border-2 border-indigo-600 p-2 rounded w-4/5 hover:bg-slate-700 transition-all mb-2"
                        @click="newChat">New Chat</button>
                </div>
                <nav class="flex flex-col w-1/5 fixed overflow-y-auto overflow-visible no-scrollbar">
                    <ul class="flex flex-col items-center overflow-visible no-scrollbar">
                        <li v-for="chatSession in chatSessionHistory"
                            class="text-sm text-center p-1 m-1 bg-slate-700 hover:bg-slate-600 rounded cursor-pointer w-4/5"
                            @click="changeChat(chatSession.id)"> {{ chatSession.title }} </li>
                    </ul>
                </nav>
            </div>
            <section class="flex flex-col w-4/5">
                <section id="chat" class="flex flex-col items-center h-full w-full">
                    <h3 class="text-xl text-center mt-1 z-30 p-2 bg-slate-700 w-fit rounded-xl">{{ currentModelName }}</h3>
                    <ul id="chatMessages" class="flex flex-col-reverse items-center w-4/5 fixed overflow-y-scroll">
                        <li v-for="chatMessage in chatHistory" class="w-full flex flex-row justify-center">
                            <p v-if="chatMessage.role == 'ai'" class="bg-slate-700 w-4/5 text-left my-2 p-2 rounded-xl">{{
                                chatMessage.message }}</p>
                            <p v-if="chatMessage.role == 'human'" class="bg-slate-800 w-4/5 text-left my-2 p-2 rounded-xl">
                                {{ chatMessage.message }}</p>
                        </li>
                    </ul>
                    <div id="messageBox"
                        class="fixed w-4/5 bottom-0 h-1/5 bg-slate-700 flex items-center justify-evenly shadow-inner">
                        <textarea
                            class="resize-none shadow appearance-none rounded py-2 px-3 text-white focus:outline-none focus:shadow-outline bg-slate-800 w-4/5 h-4/5 shadow-lg"
                            name="prompt" id="prompt" v-model="prompt" placeholder="Send a message"
                            @keyup.enter="sendPrompt"></textarea>
                        <div class="flex flex-col items-center justify-center">
                            <button class="border-2 border-slate-600 px-4 mb-8 rounded hover:bg-slate-600 transition-all"
                                @click="sendPrompt">Send</button>
                            <div class="custom-loader" v-if="showChatLoader"></div>
                        </div>

                    </div>
                </section>
            </section>
        </div>
    </section>
</template>

<script>
import BackButton from '@/components/BackButton.vue'

import { ref } from 'vue'
import { useStore } from 'vuex'

import { addDoc, setDoc, getDoc, doc, collection, query, updateDoc, arrayUnion, getDocs, orderBy } from '@firebase/firestore'
import { db } from '../firebase/index';

export default {
    name: 'ChatView',
    components: {
        BackButton
    },
    props: {
        userData: {
            type: Object
        },
        modelData: {
            type: Object
        }
    },
    setup(props, context) {
        const store = useStore()

        let chatSessionHistory = ref([])
        let chatHistory = ref([])
        let prompt = ref('')
        let showChatLoader = ref(false)

        const modelData = props.modelData
        const modelName = modelData.name
        const modelID = modelData.id
        const userData = props.userData
        const orgID = userData.orgID
        const uid = store.state.user.uid

        const currentModelName = ref(modelName)

        let chatHistoryID

        // Get the chat session history
        const getChatSessionHistory = async () => {
            // const q = query(collection(db, `users/${uid}/chats`), orderBy("dateCreated"))
            const querySnapshot = await getDocs(query(collection(db, `users/${uid}/chats`), orderBy("dateCreated", "desc")))
            querySnapshot.forEach((doc) => {
                const chatSessionHistoryData = doc.data()
                chatSessionHistory.value.push({
                    title: chatSessionHistoryData.chatTitle,
                    id: doc.id
                })
            })
        }

        const newChat = async () => {
            chatHistoryID = null

            prompt.value = ''

            chatHistory.value = []
        }

        const changeChat = async (id) => {
            if (id == chatHistoryID) {
                return
            }
            // clear out chatHistory array
            chatHistory.value.splice(0)

            chatHistoryID = id // to be used by prompt function

            // go to Firestore and get chatHistory array for chat we're changing to
            const docSnapshot = await getDoc(doc(db, `users/${uid}/chats/${id}`))

            if (docSnapshot.exists()) {
                const chatSessionData = docSnapshot.data()
                // push it to chatHistory
                chatSessionData.promptArray.forEach(messageSeries => {
                    chatHistory.value.unshift({
                        role: "ai",
                        message: messageSeries.ai
                    }, {
                        role: "human",
                        message: messageSeries.human
                    })
                })

                currentModelName.value = chatSessionData.modelName
            } else {
                console.error('No such document exists!')
            }
        }

        const initializeChat = async () => {
            // Get the prompt string
            const promptString = prompt.value
            // only run this if we are starting a new chat)
            // Clear textarea
            prompt.value = ''

            const chatTitle = await createChatTitle(promptString)

            // Initialize new document with field promptArray in Firestore, store document ID
            const docResponse = await addDoc(collection(db, `users/${uid}/chats`), {
                promptArray: [],
                dateCreated: new Date(),
                modelID: modelID,
                modelName: modelName,
                chatTitle: chatTitle
            })

            const chatHistoryID = docResponse.id

            chatSessionHistory.value.unshift({
                title: chatTitle,
                id: chatHistoryID
            })

            return chatHistoryID
        }

        const sendPrompt = async () => {
            showChatLoader.value = true
            // Get the prompt string
            const promptString = prompt.value

            // Initialize a new chat if there isn't one already
            if (chatHistory.value[0] == null) {
                chatHistoryID = await initializeChat()
            }

            // Clear out the textarea
            prompt.value = ''

            // Add the prompt to chatHistory array
            chatHistory.value.unshift({
                role: 'human',
                message: promptString
            })

            // 0. Get organization's cluster endpoint
            const orgQuery = query(doc(db, `organizations/${orgID}`))
            const querySnapshot = await getDoc(orgQuery)
            if (querySnapshot.empty) {
                console.error(`Organization doesn't exist in database`)
                return
            }

            const orgData = querySnapshot.data()
            const endpoint = orgData.endpoint

            // Send prompt to Firebase function along with modelName.
            try {
                const url = `http://127.0.0.1:5001/scribeai-development/us-central1/api/promptGPT`
                const options = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ prompt: promptString, className: modelName, endpoint: endpoint })
                };
                const promptResponse = await fetch(url, options)

                const aiResponse = await promptResponse.json()

                // Add response to chatHistory array
                chatHistory.value.unshift({
                    role: 'ai',
                    message: aiResponse.output
                })

                // Add the prompt and response to the promptArray in Firestore
                await updateDoc(doc(db, `users/${uid}/chats/${chatHistoryID}`), {
                    promptArray: arrayUnion({
                        ai: aiResponse.output,
                        human: promptString
                    })
                });
            } catch (err) {
                console.error(err)
            }

            // TODO: Add a link to cited document in response
            showChatLoader.value = false
        }

        const createChatTitle = async (promptString) => {
            const url = `http://127.0.0.1:5001/scribeai-development/us-central1/api/createTitle`
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ prompt: promptString })
            };
            const promptResponse = await fetch(url, options)

            const aiResponse = await promptResponse.json()

            return aiResponse.output
        }

        getChatSessionHistory()

        return { chatSessionHistory, chatHistory, prompt, sendPrompt, changeChat, newChat, showChatLoader, currentModelName }
    }
}
</script>

<style scoped>
#chat {
    height: 95%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

nav {
    height: 85%;
}

#chatMessages {
    height: 75%;
}
.custom-loader {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  color: #6366F1;
  box-shadow: 
    19px -19px 0 0px, 38px -19px 0 0px, 57px -19px 0 0px,
    19px 0     0 5px, 38px 0     0 5px, 57px 0     0 5px,
    19px 19px  0 0px, 38px 19px  0 0px, 57px 19px  0 0px;
  transform: translateX(-38px);
  animation: d6 2s infinite linear;
}

@keyframes d6 {
  12.5% {box-shadow: 
    19px -19px 0 0px, 38px -19px 0 0px, 57px -19px 0 5px,
    19px 0     0 5px, 38px 0     0 0px, 57px 0     0 5px,
    19px 19px  0 0px, 38px 19px  0 0px, 57px 19px  0 0px}
  25%   {box-shadow: 
    19px -19px 0 5px, 38px -19px 0 0px, 57px -19px 0 5px,
    19px 0     0 0px, 38px 0     0 0px, 57px 0     0 0px,
    19px 19px  0 0px, 38px 19px  0 5px, 57px 19px  0 0px}
  50%   {box-shadow: 
    19px -19px 0 5px, 38px -19px 0 5px, 57px -19px 0 0px,
    19px 0     0 0px, 38px 0     0 0px, 57px 0     0 0px,
    19px 19px  0 0px, 38px 19px  0 0px, 57px 19px  0 5px}
  62.5% {box-shadow: 
    19px -19px 0 0px, 38px -19px 0 0px, 57px -19px 0 0px,
    19px 0     0 5px, 38px 0     0 0px, 57px 0     0 0px,
    19px 19px  0 0px, 38px 19px  0 5px, 57px 19px  0 5px}
  75%   {box-shadow: 
    19px -19px 0 0px, 38px -19px 0 5px, 57px -19px 0 0px,
    19px 0     0 0px, 38px 0     0 0px, 57px 0     0 5px,
    19px 19px  0 0px, 38px 19px  0 0px, 57px 19px  0 5px}
  87.5% {box-shadow: 
    19px -19px 0 0px, 38px -19px 0 5px, 57px -19px 0 0px,
    19px 0     0 0px, 38px 0     0 5px, 57px 0     0 0px,
    19px 19px  0 5px, 38px 19px  0 0px, 57px 19px  0 0px}
}
</style>