<template>
    <HomeNav/>
    <section class="flex flex-col items-center min-w-full">
    <form class="mt-4 flex flex-col items-center w-80">
        <h3>Email</h3>
        <input type="email" name="email" placeholder="Email" v-model="email" required class="shadow appearance-none rounded w-full py-2 px-3 text-gray-200 leading-tight focus:outline-none focus:shadow-outline bg-slate-800">
        <h3 class="mt-2">Password</h3>
        <input type="password" name="password" placeholder="Password" v-model="password" required class="shadow appearance-none rounded w-full py-2 px-3 text-gray-300 leading-tight focus:outline-none focus:shadow-outline bg-slate-800">
    </form>
    <p id="errorMessage" v-if="errorMessage">{{ errorMessage }}</p>
    <button type="submit" @click="login" class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-4 w-80">Log In</button>
	</section>
</template>

<script>
import HomeNav from '@/components/HomeNav.vue'

import { ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default {
    components: {
        HomeNav,
    },
    setup() {
        const email = ref('')
        const password = ref('')
        const errorMessage = ref(null)

        const store = useStore()
		const router = useRouter()

        const login = async () => {
			try {
				await store.dispatch('login', {
					email: email.value,
					password: password.value
				});
				router.push('/main')
			} catch (error) {
				const errorCode = error.code;
				let errorString;
				switch (errorCode) {
					case "auth/invalid-email":
						errorString = "Invalid email";
						break;
					case "auth/user-disabled":
						errorString = "User disabled";
						break;
					case "auth/user-not-found":
						errorString = "No user found with that email, did you mean to sign up?";
						break;
					case "auth/wrong-password":
						errorString = "Wrong password";
						break;
				}
				errorMessage.value = errorString;
			}
		}
		
		return { email, password, login, errorMessage }
    }
}
</script>