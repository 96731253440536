<template>
    <MainNav />
    <ModelList @editClicked="handleEditing" @deleteClicked="handleDelete" @chatClicked="goToChat" v-if="showModelList"
        :modelArray="modelList" />
    <div class="flex justify-center">
        <button class="font-bold bg-green-500 rounded p-1 pr-3 pl-3 hover:bg-green-600 transition-all" @click="toggleTrainingView"
            v-if="showNewModelButton && hasWritePermissions">Train New Model</button>
    </div>

    <TrainingModelView @submit-clicked="toggleTrainingView" v-if="showModelTrainingView" :userData="userData" @back-clicked="toggleTrainingView" />

    <ChatView v-if="showChatView" :modelData="modelData" :userData="userData" @back-clicked="goToChat" />

    <EditModelView v-if="showEditView" :modelData="modelData" :userData="userData" @back-clicked="handleEditing(null)" />

    <ConfirmDelete :showModal="showModal" :showDeleteLoader="showDeleteLoader" @confirmed="deleteModel" @cancelled="showModal = false" modalMessage="Are you sure you want to delete this model? This
                            action
                            cannot be undone. This model will be deleted for all users who have access to it." />
</template>

<script>
import MainNav from '@/components/MainNav.vue';
import ModelList from '@/components/ModelList.vue';
import TrainingModelView from '@/components/TrainingModelView.vue';
import ConfirmDelete from '@/components/ConfirmDelete.vue';
import ChatView from '@/components/ChatView.vue';
import EditModelView from '@/components/EditModelView.vue'

import { query, getDoc, doc, deleteDoc, updateDoc } from '@firebase/firestore';
import { db } from '../firebase/index';
import { getStorage, deleteObject, ref as storageRef } from "firebase/storage";

import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'

export default {
    name: 'Models',
    components: {
        MainNav,
        ModelList,
        TrainingModelView,
        ConfirmDelete,
        ChatView,
        EditModelView
    },
    setup() {
        const store = useStore() // local
        const storage = getStorage(); // firebase file storage

        let showModal = ref(false);

        let showNewModelButton = ref(true)
        let showModelList = ref(true)
        let showChatView = ref(false)
        let showModelTrainingView = ref(false)
        let showEditView = ref(false)
        let confirmingDelete = ref(false)
        let hasWritePermissions = ref(false)
        let hasReadPermissions = ref(false)
        let hasUpdatePermissions = ref(false)
        let hasDeletePermissions = ref(false)
        let userData = ref(null)
        let modelList = ref([])

        let modelData = ref({})

        let showDeleteLoader = ref(false)

        const toggleTrainingView = function () {
            showNewModelButton.value = !showNewModelButton.value
            showModelList.value = !showModelList.value
            showModelTrainingView.value = !showModelTrainingView.value
            getModels()
        }

        const handleEditing = async (mID) => {
            showNewModelButton.value = !showNewModelButton.value
            showModelList.value = !showModelList.value
            showEditView.value = !showEditView.value
            modelData.value = modelList.value.find(model => model.id === mID);
        }

        const handleDelete = async (mID) => {
            modelData.value = modelList.value.find(model => model.id === mID);
            showModal.value = true
        }

        const deleteModel = async () => {
            // TODO: delete model from EVERY user who has permissions for it (may need a document with list of users w/ permission)
            showDeleteLoader.value = true
            const mID = modelData.value.id

            // Step 1. Delete associated files from Cloud Storage
            try {
                const modelDetailsQuery = await getDoc(doc(db, `organizations/${userData.value.orgID}/datasets/${mID}`))
                if (modelDetailsQuery.empty) {
                    console.error(`Organization doesn't exist in database`)
                    return
                }
                const modelDetails = modelDetailsQuery.data()
                console.log('got model details')

                const fileList = modelDetails.fileList

                const deleteFilePromises = []
                fileList.forEach(file => {
                    const fileRef = storageRef(storage, `${file.storagePath}`)
                    deleteFilePromises.push(handleDeleteFile(fileRef))
                })

                const deleteFileResults = await Promise.all(deleteFilePromises)
                console.log('deleted files')
            } catch (error) {
                console.error(error)
            }
            
            // Step 2. Delete model from Firestore organization/models
            try {
                await deleteDoc(doc(db, `organizations/${userData.value.orgID}/datasets/${mID}`))
                console.log('deleted dataset')
                // Future: Add some kind of indicator to chats with this model that show it no longer exists, but keep chat logs
            } catch (error) {
                console.error(error)
            }

            // Step 3. Delete class from Weaviate
            try {
                const orgQuery = query(doc(db, `organizations/${userData.value.orgID}`))
                const querySnapshot = await getDoc(orgQuery)
                if (querySnapshot.empty) {
                    console.error(`Organization doesn't exist in database`)
                    return
                }

                const orgData = querySnapshot.data()
                const endpoint = orgData.endpoint
                console.log('got endpoint')

                const url = `${process.env.VUE_APP_FUNCTIONS_URL}/api/deleteWeaviateSchema`
                const options = {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ schemaName: modelData.value.name, endpoint: endpoint })
                };

                await fetch(url, options)
                console.log('deleted schema')
            } catch (error) {
                console.error(error)
            }

            // Step 4. Update DOM
            try {
                const index = modelList.value.findIndex(model => model.id === mID);
                modelList.value.splice(index, 1);
            } catch (error) {
                console.error(error)
            }

            // Step 5. Delete model from modelAccess list in user (done by just updating modelAccess with new list) (note this comes last since we're using modelList.value, which doesn't get updated until step 4)
            try {
                const userRef = doc(db, `users/${store.state.user.uid}`)
                await updateDoc(userRef, {
                    modelAccess: modelList.value
                })
            } catch (error) {
                console.error(error)
            }

            showDeleteLoader.value = false
            showModal.value = false
        }

        const goToChat = async (mID) => {
            showNewModelButton.value = !showNewModelButton.value
            showModelList.value = !showModelList.value
            showChatView.value = !showChatView.value
            modelData.value = modelList.value.find(model => model.id === mID);
        }

        const getModels = async () => {
            const uid = store.state.user.uid

            const userQuery = query(doc(db, `users/${uid}`))
            const querySnapshot = await getDoc(userQuery)
            if (querySnapshot.empty) {
                console.error(`User doesn't exist in database`)
                return
            }

            const fetchedUserData = querySnapshot.data()

            if (!fetchedUserData.modelAccess) {
                ModelList.value = []
            }

            [hasWritePermissions.value, hasReadPermissions.value, hasUpdatePermissions.value, hasDeletePermissions.value] = fetchedUserData.modelAdmin

            modelList.value = fetchedUserData.modelAccess

            userData.value = fetchedUserData
        }

        const handleDeleteFile = async (storageRef) => {
            return new Promise(function (resolve, reject) {
                deleteObject(storageRef).then((response) => {
                    resolve(response)
                }).catch(error => {
                    reject(`Could not delete file: ${error})`)
                })
            })
        }

        getModels()

        return { showNewModelButton, showModelList, showModelTrainingView, showChatView, confirmingDelete, hasWritePermissions, hasReadPermissions, hasUpdatePermissions, hasDeletePermissions, userData, modelList, toggleTrainingView, handleEditing, handleDelete, goToChat, modelData, showModal, deleteModel, showDeleteLoader, showEditView }
    },
}
</script>