<template>
    <HomeNav/>
    <section class="flex justify-center min-w-full">
        <form class="mt-4 flex flex-col justify-center w-80" @submit.prevent="signup">
            <input type="firstName" name="firstName" placeholder="First Name" v-model="firstName" required class="shadow appearance-none rounded py-2 px-3 text-gray-200 leading-tight focus:outline-none focus:shadow-outline bg-slate-800">
            <input type="lastName" name="lastName" placeholder="Last Name" v-model="lastName" required class="shadow appearance-none rounded py-2 px-3 text-gray-200 leading-tight focus:outline-none focus:shadow-outline bg-slate-800 mt-1">
            <input type="email" name="email" placeholder="Email address" v-model="email" required class="shadow appearance-none rounded py-2 px-3 text-gray-200 leading-tight focus:outline-none focus:shadow-outline bg-slate-800 mt-1">
            <input type="password" name="password" placeholder="Create a Password" v-model="password" required class="shadow appearance-none rounded w-full py-2 px-3 text-gray-200 leading-tight focus:outline-none focus:shadow-outline bg-slate-800 mt-1">
            <input type="organizationID" name="orgID" placeholder="Organization ID" v-model="orgID" required class="shadow appearance-none rounded w-full py-2 px-3 text-gray-200 leading-tight focus:outline-none focus:shadow-outline bg-slate-800 mt-1">
            <button type="button" @click="signup" class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-4">Sign Up</button>
        </form>
    </section>
    <p v-if="errorMessage" id="errorMessage" style="color: red;">{{ errorMessage }}</p>
</template>

<script>
import HomeNav from '@/components/HomeNav.vue'

import { auth } from '@/firebase'
import { updateProfile } from 'firebase/auth';

import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router';

export default {
    name: 'Sign Up',
    components: {
        HomeNav,
    },
    setup() {
        const firstName = ref('')
        const lastName = ref('')
        const email = ref('')
        const password = ref('')
        const orgID = ref('')

        const errorMessage = ref(null)

        const store = useStore()
        const router = useRouter()

        const signup = async () => {
            try {
                await store.dispatch('signup', {
                    firstName: firstName.value,
                    lastName: lastName.value,
                    email: email.value,
                    password: password.value,
                    orgID: orgID.value,
                })
                await updateProfile(auth.currentUser, {
                    firstName: firstName.value,
                    lastName: lastName.value,
                    displayName: `${firstName.value} ${lastName.value}`,
                })
                router.push('/main')
            } catch(error) {
                let errorString
				const errorCode = error.code;
				switch (errorCode) {
					case "auth/email-already-in-use":
						errorString = "Email already in use";
						break;
					case "auth/invalid-email":
						errorString = "Invalid email";
						break;
					case "auth/operation-not-allowed":
						errorString = "Operation not allowed";
						break;
					case "auth/weak-password":
						errorString = "Weak password (Please choose a password with at least 6 characters)";
						break;
				}
				errorMessage.value = errorString;
            }
        }

        return { signup, firstName, lastName, email, password, orgID, errorMessage }
    }
}
</script>