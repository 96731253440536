<template>
    <section class="flex justify-center items-center flex-col mt-2">
        <h3>Your Models</h3>
        <table class="border-separate border-spacing-2">
            <tr v-for="model in modelArray">
                <td>
                    <h4 class="font-black pr-2 border-r-2">{{ model.name }}</h4>
                </td>
                <td>
                    <p>{{ model.description }}</p>
                </td>
                <td><button @click="$emit('editClicked', model.id)" class="bg-blue-500 rounded pr-2 pl-2 mr-2 ml-2 hover:bg-blue-600 transition-all">Edit Model</button></td>
                <td><button @click="$emit('deleteClicked', model.id)" class="bg-red-500 rounded pr-2 pl-2 mr-2 ml-2 hover:bg-red-600 transition-all">Delete Model</button></td>
                <td><button @click="$emit('chatClicked', model.id)" class="bg-blue-500 rounded pr-2 pl-2 mr-2 ml-2 hover:bg-blue-600 transition-all">Chat</button></td>
            </tr>
        </table>
    </section>
</template>

<script>


export default {
    name: 'ModelList',
    props: {
        modelArray: {
            type: Array,
            default: [],
        }
    },
}

</script>