<template>
    <nav class="flex justify-center bg-slate-800 z-50 relative">
        <div class="m-2 hover:font-bold">
            <router-link to="/main">
                <h3>Main</h3>
            </router-link>
        </div>
        <div class="m-2 hover:font-bold">
            <router-link to="/models">
                <h3>Models</h3>
            </router-link>
        </div>
        <div class="m-2 hover:font-bold">
            <h3 id="logoutButton" @click="logOut" class="hover:cursor-pointer">Logout</h3>
        </div>
    </nav>
</template>

<script>
// import { auth } from "@/firebase"
// import { ref } from 'vue'
import { useStore } from "vuex"
import { useRouter } from "vue-router"

export default {
    name: 'MainNav',
    setup() {
        const store = useStore()
        const router = useRouter()

        const logOut = async () => {
            await store.dispatch('logout')
            router.push('/login')
        }

        return { logOut }
    }
}
</script>

<style scoped>
nav a.router-link-exact-active {
  color: #42b983;
  font-weight: bold;
}

nav {
    height: 5%;
}
</style>