<template>
    <div id="training">
        <div class="grid grid-cols-6 mt-2">
            <BackButton @back-clicked="$emit('back-clicked')" class="flex justify-center" v-show="showSubmit" />
            <h2 class="text-2xl text-center font-bold col-start-2 col-end-6">New Dataset</h2>
        </div>
        <div class="flex flex-col items-center w-screen h-max">
            <div :class="divClass()" class="transition-all duration-500 flex items-center flex-col w-screen">
                <input
                    class="text-center shadow appearance-none rounded h-8 w-1/5 h-5 py-2 px-3 text-white focus:outline-none focus:shadow-outline bg-slate-800 mt-1"
                    type="text" name="trainingText" v-model="name" placeholder="Name your model">
                <!-- <h4 class="text-xl text-center mt-2">Description:</h4> -->
                <textarea
                    class="resize-none shadow appearance-none rounded w-3/5 h-1/5 py-2 px-3 text-white focus:outline-none focus:shadow-outline bg-slate-800 mt-1"
                    name="description" id="description" v-model="description" placeholder="Describe your model"></textarea>
                <!-- <h4 class="text-xl text-center mt-2">Enter text to add:</h4>
            <textarea
                class="resize-none shadow appearance-none rounded w-4/5 h-4/5 py-2 px-3 text-white700 focus:outline-none focus:shadow-outline bg-slate-800 mt-1 mb-2"
                type="text" name="trainingText" v-model="text"></textarea> -->
                <label for="upload-pdf"
                    class="mt-2 block mb-2 p-2 text-sm font-medium text-white dark:text-white bg-slate-500 rounded-lg hover:bg-slate-400">Upload
                    PDFs</label>
                <input @change="setFilePath" type="file" id="upload-pdf" accept=".pdf" multiple="multiple" class="hidden">
                <ul>
                    <h4 class="text-center font-bold">Selected Files:</h4>
                    <li v-for="file in fileArray" class="font-sm">
                        <p>{{ file.name }}</p>
                    </li>
                </ul>
                <button class="font-bold bg-green-700 rounded p-1 pr-3 pl-3 hover:bg-green-600 mt-10" @click="trainModel"
                    v-if="showSubmit">Submit</button>
                <p v-if="showErrorMessage">{{ errorMessage }}</p>
            </div>
            <span class="loader mt-12" v-show="showLoader"></span>
        </div>
    </div>
</template>

<script>
import BackButton from './BackButton.vue';

import { ref } from 'vue'
import { query, addDoc, setDoc, getDoc, updateDoc, doc, collection, arrayUnion } from '@firebase/firestore';
import { db } from '../firebase/index';
import { getStorage, ref as storageRef, uploadBytes } from "firebase/storage";
import { useStore } from 'vuex'

export default {
    name: 'TrainingModelView',
    emits: ["submit-clicked", "back-clicked"],
    components: {
        BackButton
    },
    props: {
        userData: {
            type: Object,
        }
    },
    setup(props, context) {
        const store = useStore()
        const name = ref('')
        const description = ref('')
        const text = ref('')
        let errorMessage = ref('')
        let showErrorMessage = ref(false)
        let fileArray = ref([])

        let showSubmit = ref(true)
        let showLoader = ref(false)
        let shrinkInputs = ref(false)

        const divClass = () => (shrinkInputs.value ? 'h-0 opacity-0' : 'h-96 opacity-100');

        let filePaths

        const userData = props.userData
        const orgID = userData.orgID
        const uid = store.state.user.uid

        const trainModel = async () => {
            //TODO: ADD VALIDATION THAT NOT DUPLICATE MODEL NAME

            const nameValidity = checkNameValidity()
            if (!nameValidity) {
                return
            }

            showErrorMessage.value = false
            showSubmit.value = false
            showLoader.value = true
            shrinkInputs.value = true

            // 0. Get organization's cluster endpoint
            const orgQuery = query(doc(db, `organizations/${orgID}`))
            const querySnapshot = await getDoc(orgQuery)
            if (querySnapshot.empty) {
                console.error(`Organization doesn't exist in database`)
                return
            }

            const orgData = querySnapshot.data()
            
            const endpoint = orgData.endpoint

            // 1. Create a new dataset in Firestore with an empty document
            const docResponse = await addDoc(collection(db, `organizations/${orgID}/datasets`), {
                fileName: name.value
            })

            const datasetID = docResponse.id

            // 2. Upload file(s) to Cloud Storage (create new folder under organization/datasets/)
            const fileKeys = Object.keys(fileArray.value)

            const uploadPromises = []
            fileKeys.forEach(key => {
                uploadPromises.push(uploadToFirebaseStorage(fileArray.value[key], datasetID))
            })

            const uploadResults = await Promise.all(uploadPromises)

            // 3. Write model name, description to Firestore
            const formattedFileArray = []
            // Information about each file in the model:
            Array.prototype.forEach.call(fileArray.value, function (file) {
                formattedFileArray.push({
                    fileName: file.name,
                    fileType: file.type,
                    fileSize: file.size,
                    storagePath: `organizations/${orgID}/datasets/${datasetID}/${file.name}`
                })
            });

            await setDoc(doc(db, `organizations/${orgID}/datasets/${datasetID}`), {
                name: name.value,
                description: description.value,
                fileList: formattedFileArray,
                createdBy: [uid, `${userData.firstName} ${userData.lastName}`]
            });

            // Write Model Access for Creating User

            await updateDoc(doc(db, `users/${uid}`), {
                modelAccess: arrayUnion({
                    id: datasetID,
                    description: description.value,
                    name: name.value
                })
            })

            // 4. Send full text to Cloud Function to handle schema creation, vectorization, data input into vector database
            if (filePaths) {
                const vectorUploadPromises = []
                Array.prototype.forEach.call(fileArray.value, function (filePath) {
                    vectorUploadPromises.push(processFile(filePath, endpoint));
                })
                const vectorUploadResults = await Promise.all(vectorUploadPromises);
            }


            showErrorMessage.value = false
            showLoader.value = false
            shrinkInputs.value = false

            // 5. Emit click event to return to list
            context.emit('submit-clicked')
        }

        const setFilePath = (e) => {
            filePaths = e.target.files
            fileArray.value = e.target.files
            return
        }

        const checkNameValidity = () => {
            const isValid = /^[a-zA-Z0-9_]+$/.test(name.value);
            if (!isValid) {
                showErrorMessage.value = true
                errorMessage.value = 'Please use only letters, numbers, and underscores in the name!'
                return false
            }
            return true
        }

        const uploadToFirebaseStorage = async (file, datasetID) => {
            const storage = getStorage()
            const fileName = file.name
            const cloudStorageRef = storageRef(storage, `organizations/${orgID}/datasets/${datasetID}/${fileName}`)

            return new Promise(function (resolve, reject) {
                uploadBytes(cloudStorageRef, file).then(() => {
                    resolve('Upload successful')
                }).catch(error => {
                    reject(`Upload failed: ${error}`)
                })
            })
        }

        const processFile = (filePath, endpoint) => {
            return new Promise(async (resolve, reject) => {
                const fr = new FileReader();

                fr.onload = async function () {
                    const pdf = await pdfjsLib.getDocument({ data: fr.result }).promise;
                    let text = "";

                    for (let i = 1; i <= pdf.numPages; i++) {
                        const page = await pdf.getPage(i);
                        const content = await page.getTextContent();
                        text += content.items.map(item => item.str).join(' ') + "\n\n";
                    }

                    try {
                        const result = await sendTextToCloudFunction(text, name.value, description.value, filePath.name, endpoint);
                        resolve(result);
                    } catch (error) {
                        reject(error);
                    }
                };

                fr.readAsArrayBuffer(filePath);
            });
        };

        const sendTextToCloudFunction = async (text, className, description, fileName, endpoint) => {
            const url = `http://127.0.0.1:5001/scribeai-development/us-central1/api/handlePDF`
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ text: text, className: className, description: description, documentTitle: fileName, endpoint: endpoint })
            };

            return new Promise(function (resolve, reject) {
                fetch(url, options).then((response) => {
                    resolve(response)
                }).catch(error => {
                    reject(`Cloud function failed: ${error})`)
                })
            })
        }

        function unixTimestamp() {
            return Math.floor(Date.now() / 1000)
        }

        return { trainModel, setFilePath, name, description, text, fileArray, showErrorMessage, errorMessage, showSubmit, showLoader, divClass }
    },
}
</script>

<style scoped>
.loader {
    color: #27ed5f;
    position: relative;
    font-size: 11px;
    background: #27ed5f;
    animation: escaleY 1s infinite ease-in-out;
    width: 1em;
    height: 4em;
    animation-delay: -0.16s;
}

.loader:before,
.loader:after {
    content: '';
    position: absolute;
    top: 0;
    left: 2em;
    background: #27ed5f;
    width: 1em;
    height: 4em;
    animation: escaleY 1s infinite ease-in-out;
}

.loader:before {
    left: -2em;
    animation-delay: -0.32s;
}

@keyframes escaleY {

    0%,
    80%,
    100% {
        box-shadow: 0 0;
        height: 4em;
    }

    40% {
        box-shadow: 0 -2em;
        height: 5em;
    }
}

#training {
    height: 95%;
}
</style>