<template>
    <div>
        <button class="px-4 py-1 bg-green-500 text-white hover:bg-green-600 transition-all rounded-md font-bold" @click="$emit('back-clicked')">Go Back</button>
    </div>
</template>

<script>
    export default {
        name: 'BackButton',
        emits: ['back-clicked'],
        setup() {
            const goBack = async() => {
                context.emit('back-clicked')
            }
        }
    }
</script>