<template>
    <div class="flex justify-center bg-slate-800">
      <h1 class="text-center text-6xl m-4 font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-cyan-300 to-indigo-400 w-fit">ElephantGPT</h1>
    </div>
    <nav class="flex justify-center">
        <router-link to="/" class="m-2 hover:font-bold">Home</router-link>
        <router-link to="/signup" class="m-2 hover:font-bold">Signup</router-link>
        <router-link to="/login" class="m-2 hover:font-bold">Login</router-link>
    </nav>
</template>

<script>
export default {
    name: 'HomeNav',
}
</script>

<style>
nav a.router-link-exact-active {
  color: #42b983;
  font-weight: bold;
}
</style>