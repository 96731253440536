import { createStore } from "vuex";

// Firebase imports
import { auth, db } from "@/firebase";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, onAuthStateChanged } from "firebase/auth";
import { setDoc, doc, Timestamp, addDoc, collection } from 'firebase/firestore'

export const store = createStore({
	state: {
		user: null,
		authIsReady: false,
	},
	mutations: {
		setUser(state, payload) {
			state.user = payload;
		},
		setAuthIsReady(state, payload) {
			state.authIsReady = payload;
		},
	},
	actions: {
		async signup(context, { firstName, lastName, email, password, orgID }) {
			const res = await createUserWithEmailAndPassword(auth, email, password);
			if (res) {
				await setDoc(doc(db, `users`, `${res.user.uid}`), {
					firstName: firstName,
					lastName: lastName,
					email: email,
					orgID: orgID,
					dateCreated: new Date(),
                    modelAccess: [],
					modelAdmin: [false, false, false, false], // create, read, update, delete
					userAdmin: false
				}); // Sets user document in users collection
				context.commit('setUser', res.user);
			} else {
				throw new Error('Error creating user');
			}
		},
		async login(context, {email, password}) {
			const res = await signInWithEmailAndPassword(auth, email, password);
			// console.log(`Login: ${res.user}`);
			if (res) {
				context.commit('setUser', res.user);
			} else {
				throw new Error('Error logging in');
			}
		},
		async logout(context) {
			await signOut(auth);
			context.commit('setUser', null);
		},
	},
});

const unSub = onAuthStateChanged(auth, (user) => {
	store.commit('setAuthIsReady', true);
	store.commit('setUser', user);
	unSub();
});

export default store;