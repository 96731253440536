import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import Signup from '@/views/Signup.vue'
import Login from '@/views/Login.vue'
import Main from '@/views/Main.vue'
import Models from '@/views/Models.vue'

import { auth } from '@/firebase'
import { onAuthStateChanged } from 'firebase/auth'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: 'Login',
      requiresAuth: false,
    }
  },
  {
    path: '/signup',
    name: 'signup',
    // component: () => import('../views/Signup.vue') <- Lazy loading
    component: Signup,
    meta: {
      title: 'Login',
      requiresAuth: false,
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: 'Login',
      requiresAuth: false,
    }
  },
  {
    path: '/main',
    name: 'main',
    component: Main,
    meta: {
      title: 'Main',
      requiresAuth: true,
    }
  },
  {
    path: '/models',
    name: 'models',
    component: Models,
    meta: {
      title: 'Models',
      requiresAuth: true,
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  const usr = await user()

  if (usr && (to.path === "/login" || to.path === "/signup")) {
    next('/main')
  } else if ( !usr && to.meta.requiresAuth ) {
    next('/login')
  } else {
    next()
  }

})

router.afterEach((to, from) => {
	if (to.meta.title) {
		document.title = `${to.meta.title} - ElephantGPT`
	}
});

const user = () => {
  return new Promise((resolve, reject) => {
    const unSub = onAuthStateChanged(auth, user => {
      unSub()
      resolve(user)
    }, reject)
  })
}


export default router
